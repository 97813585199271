table {
    width: 100%;
}


::ng-deep .table-design-xs {
    overflow-x: auto;

    th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type{
        padding-right: 0px;
    }
    th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type{
        padding-left: 0px;
    }
    th.mat-header-cell,.mat-row .mat-cell {
        padding: 0px 10px;
    }
}

.searchForm-xs {
    display: unset !important;

    button {
        margin-top: 10px;
    }
}

::ng-deep {
    .table-design {

        .mat-table thead {
            background-color: rgb(247 250 251);
        }

        th.mat-header-cell {
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            color: #000;
        }

        .mat-row .mat-cell {
            border-bottom: 1px solid transparent;
            border-top: 1px solid transparent;
            cursor: pointer;
        }

        .mat-row:hover {
            background-color: rgb(211 211 211 / 41%);
        }
        th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type{
            padding-right: 0px;
        }
        th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type{
            padding-left: 5px;
        }
        th.mat-header-cell, td.mat-cell, td.mat-footer-cell{
            padding: 0px 3px;
        }

        tr.mat-header-row {
            height: 46px;
        }
        td, th {
         width: auto !important;
       }
    }
}

// form css
::ng-deep .example-form-search {
    .mat-form-field-wrapper {
        padding-bottom: 0px;
    }

    .mat-form-field-infix {
        border-top: 0px;
    }

    .mat-form-field-appearance-outline .mat-form-field-suffix {
        top: 0.4em !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: #000000;
    }

    .mat-form-field-label {
        color: #0c0c0c;
    }

    .mat-icon {
        color: #000000;
    }

    .mat-form-field.mat-focused .mat-form-field-label {
        color: #000000;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-1em) scale(0.75);
    }
}

// table menu css
::ng-deep {
    .mat-menu-content {
        display: flex;
        padding: 0px !important;
    }

    .mat-menu-panel {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        min-height: 45px;
    }

    .mat-menu-item .mat-icon {
        margin-right: 0px;
    }

    .mat-menu-item {
        padding: 0px 12px;
    }
}

// Button css
.add-button {
    padding: 0px 20px;
    border: 1px solid transparent;
}

.add-button:hover {
    background-color: transparent;
    color: #f44336;
    border: 1px solid #f44336;
}
// Submit btn css
.Submit {
    padding: 0px 20px;
    border: 1px solid transparent;
}

.Submit:hover {
    background-color: transparent;
    color: #3f51b5;
    border: 1px solid #3f51b5;
}

.tab-sec {
    .mat-tab-header {
        width: 70%;
    }
}

.tab-sec-md {
    .mat-tab-header {
        border-bottom: 0px;
        width: 100% !important;
    }
}

::ng-deep .tab-sec {
    .mat-tab-header {
        border-bottom: 0px !important;
    }

    .mat-tab-label {
        padding: 0px 16px;
        min-width: 100px;
        margin: 0px 10px;
        font-size: 18px;
    }

    .mat-tab-label-content {
        color: #000;
        font-weight: 600;
    }

    // .mat-tab-label.mat-tab-label-active {
    //     border-bottom: 1px solid #000;
    // }
    .mat-ink-bar {
        background-color: #000;
    }

    .mat-tab-group.mat-primary .mat-ink-bar {
        background-color: #000;
    }

    .mat-tab-body-wrapper {
        margin-top: 20px;
    }

    .mat-tab-body-content {
        overflow: unset;
    }

    .mat-tab-body.mat-tab-body-active {
        padding: 1px;
    }
}

.mat-paginator {
    display: block;
    margin-top: 10px;
}


// Dialog box custom CSS 
::ng-deep .dialog-design {
    .mat-dialog-container{
        padding: 0px 0px 25px !important;
        border-radius: 10px !important;
    }
    .mat-divider{
        width: 100% !important;
        border-top-width: 2px !important;
        color: rgb(216, 216, 216);

    }
    .mat-dialog-content{
        margin: 0px !important;
        padding-top: 10px !important;
        max-height: 100% !important;
    }
    // .remove-btn{
    //     margin-top: 0px !important;
    // }
  }

.color-primary{
   color: #3f51b5;
}
.total-follow-list {
    padding: 3px 7px;
    border: 1px solid #000;
    border-radius: 5px;
}
.text-center{
    text-align: center;
}

.w-100{
    width: 100%;
}

.save-button{
    border: 1px solid #673ab7;
}
.save-button:hover{
    background-color: transparent;
    color: #673ab7;
}

::ng-deep .mat-placeholder-required {
    color: red;
  }


  .cancel-button{
    border: 1px solid #f44336;
 }
 .cancel-button:hover{
    background-color: transparent;
    color: #f44336;
 }

// datePicker CSS

::ng-deep .date-range{
    .mat-form-field-wrapper {
        padding-bottom: 0px;
      }
      .mat-form-field-appearance-outline .mat-form-field-outline {
          color: #000000 !important;
      }
  
      .mat-form-field-label {
          color: #0c0c0c !important;
      }
  
      .mat-icon {
          color: #000000 !important;
      }
  
      .mat-form-field.mat-focused .mat-form-field-label {
          color: #000000 !important;
      }

      .mat-form-field-type-mat-date-range-input .mat-form-field-infix{
        height: 14px;
      }
}