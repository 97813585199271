/* You can add global styles to this file, and also import other style files */
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./scss/common.scss";
// @import "./scss/material";
@import "./scss/custom-theme.scss";
@import '~font-awesome/css/font-awesome.css';
@import './scss/animate.scss';
@import "~aos/dist/aos.css";

body {
    margin: 0;
    font-family: Lato;
}

html,
body {
    height: 100%;
    overflow-x: hidden;
}

.mat-display-1 {
    font-weight: 600;
    margin: 0;
    font-size: 36px;
}

.mat-display-2 {
    font-weight: 600;
    margin: 0;
    font-size: 34px;
}

.mat-display-3 {

    font-size: 350 56px/56px Quicksand;
    font-family: Quicksand;
    letter-spacing: -0.02em;
    margin: 0 0 25px;
}

.mat-display-4 {
    font-size: 80px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: none;
    line-height: 90px;
}


.mat-h1 {
    font-weight: 600;
    margin: 0;
}

.mat-h2 {
    font-weight: 600;
    margin: 0;
    font-size: 20px;
}

.mat-h3,
.mat-h4 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

// {
//   margin: 0;
//   font-size: 14px;
// }

//provide space

.mat-h5 {
    font-weight: 400;
    margin: 0;
    font-size: 14px !important;
}

.mat-h6 {
    font-weight: 500 !important;
    margin: 0;
    font-size: 13px !important;
}

.padding-top {
    padding: 10px;
}


//height of flexlayout
.row-height {
    min-height: 100vh;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.display-none {
    display: none;
}

mat-icon {
    cursor: pointer;
    color: #87888a;
}

.mb-20 {
    margin-bottom: 20px !important;
}

//Snackbar
.red-snackbar {
    background: mat-color($mat-red, 500);
    color: #fff;
}

.green-snackbar {
    background: mat-color($mat-green, 500);
    color: #fff;
}

.backgroud-color-white {
    background-color: #fff;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: #000000;
    animation: spinner 0.8s linear infinite;
}

.fa-whatsapp {
    color: #25D366;
}

.fa-instagram {
    color: #C13584;
}
.fa-facebook{
    color: #3b5998;
}